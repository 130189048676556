import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import store from './stores/store';

// Create a root and render the App inside it
const root = document.getElementById('root');
const reactRoot = createRoot(root);
reactRoot.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);
