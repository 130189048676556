// App.js
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import Table from './pages/Table';

function App() {
  const handleScan = (tableId) => {
    // Ensure tableId is not null before setting in localStorage
    if (tableId) {
      console.log('Setting tableId in localStorage:', tableId);
      localStorage.setItem('scannedTableId', tableId);
    } else {
      console.log('Received null tableId, not setting in localStorage.');
    }

  
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Layout />} />
            <Route
              path="tables/:tableId"
              element={<Table handleScan={handleScan} />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
