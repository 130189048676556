// actions.js

export const ADD_TO_CART = 'ADD_TO_CART';
export const DELETE_FROM_CART = 'DELETE_FROM_CART';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_FOODS_BY_CATEGORY = 'SET_FOODS_BY_CATEGORY';
export const PLACE_ORDER_REQUEST = 'PLACE_ORDER_REQUEST';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE';
export const SET_SCANNED_TABLE_ID = 'SET_SCANNED_TABLE_ID';
export const FETCH_FOODS_REQUEST = 'FETCH_FOODS_REQUEST';
export const FETCH_FOODS_SUCCESS = 'FETCH_FOODS_SUCCESS';
export const FETCH_FOODS_FAILURE = 'FETCH_FOODS_FAILURE';

const API_URL = process.env.REACT_APP_API_URL;

export const addToCart = (item) => ({
  type: ADD_TO_CART,
  payload: {
    ...item,
    img: item.img,
    requests: item.requests,
    quantity: 1,
  },
});

export const deleteFromCart = (index) => ({
  type: DELETE_FROM_CART,
  payload: index,
});

export const clearCart = () => ({
  type: CLEAR_CART,
});

export const setCategories = (categories) => ({
  type: SET_CATEGORIES,
  payload: categories,
});

export const setFoodsByCategory = (foodsByCategory) => ({
  type: SET_FOODS_BY_CATEGORY,
  payload: foodsByCategory,
});

const commissionRates = {
  'Main course': 0.20,
  'Side Dish': 0.30,
  'Snacks': 0.10,
  'Desserts': 0.10,
  'Appetizer': 0.10,
  'Alcohol': 0.10,
  'Drinks': 0.10,
  'Soup': 0.10,
};

export const placeOrder = (amount, foods) => {
  return async (dispatch) => {
    dispatch({ type: PLACE_ORDER_REQUEST });

    try {
      const tableId = localStorage.getItem('scannedTableId');
      const merchantId = localStorage.getItem('merchantId');

      if (!tableId) {
        throw new Error('Scanned tableId not found in localStorage');
      }

      if (!merchantId) {
        throw new Error('MerchantId not found in localStorage');
      }

      let totalCommission = 0;
      foods.forEach((item) => {
        const categoryCommission = commissionRates[item.category] || 0.10;
        totalCommission += categoryCommission * item.quantity;
      });

      const sst = amount * 0.08;
      const serviceCharge = amount * 0.10;
      const totalAmount = amount + sst + serviceCharge;

      const itemsByCategory = {};

      foods.forEach((item) => {
        if (!itemsByCategory[item.category]) {
          itemsByCategory[item.category] = [];
        }
        itemsByCategory[item.category].push({
          foodId: item._id,
          quantity: item.quantity,
          requests: item.requests,
        });
      });

      const orderDetails = [];

      for (const category in itemsByCategory) {
        const response = await fetch(`${API_URL}/orders`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            merchantId: `${merchantId}`
          },
          body: JSON.stringify({
            tableId,
            merchantId,
            amount,
            commission: totalCommission,
            sst,
            serviceCharge,
            foods: itemsByCategory[category],
          }),
        });

        if (!response.ok) {
          throw new Error(`Order placement failed for category ${category}`);
        }

        const data = await response.json();

        orderDetails.push({
          category,
          orderId: data._id,
        });
      }

      dispatch({ type: PLACE_ORDER_SUCCESS, payload: orderDetails });
      dispatch(clearCart());

    } catch (error) {
      dispatch({ type: PLACE_ORDER_FAILURE, payload: error.message });
    }
  };
};



export const fetchFoodsRequest = () => ({
  type: FETCH_FOODS_REQUEST,
});

export const fetchFoodsSuccess = (foods) => ({
  type: FETCH_FOODS_SUCCESS,
  payload: foods,
});

export const fetchFoodsFailure = (error) => ({
  type: FETCH_FOODS_FAILURE,
  payload: error,
});

export const fetchFoods = () => {
  return async (dispatch) => {
    dispatch(fetchFoodsRequest());

    try {
      const response = await fetch(`${API_URL}/foods/mfoods`);
      const data = await response.json();

      dispatch(fetchFoodsSuccess(data));
    } catch (error) {
      dispatch(fetchFoodsFailure(error.message));
    }
  };
};

export const CLEAR_CART = 'CLEAR_CART';

export const setScannedTableId = (tableId) => ({
  type: SET_SCANNED_TABLE_ID,
  payload: tableId,
});
