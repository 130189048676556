// reducers.js

import {
  ADD_TO_CART,
  DELETE_FROM_CART,
  SET_CATEGORIES,
  SET_FOODS_BY_CATEGORY,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILURE,
  SET_SCANNED_TABLE_ID,
  FETCH_FOODS_REQUEST,
  FETCH_FOODS_SUCCESS,
  FETCH_FOODS_FAILURE,
} from '../actions/actions';

const initialState = {
  cart: [],
  categories: [],
  foodsByCategory: {},
  order: {
    loading: false,
    success: null,
    error: null,
  },
  scannedTableId: null,
  foods: [], // Add foods array to initial state
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        cart: [...state.cart, action.payload],
      };

    case DELETE_FROM_CART:
      const updatedCart = state.cart.filter((item, index) => index !== action.payload);
      return {
        ...state,
        cart: updatedCart,
      };

    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };

    case SET_FOODS_BY_CATEGORY:
      return {
        ...state,
        foodsByCategory: action.payload,
      };

    case PLACE_ORDER_REQUEST:
      return {
        ...state,
        order: {
          loading: true,
          success: null,
          error: null,
        },
      };

    case PLACE_ORDER_SUCCESS:
      return {
        ...state,
        order: {
          loading: false,
          success: action.payload,
          error: null,
        },
        cart: [], // Clear the cart after successful order placement
      };

    case PLACE_ORDER_FAILURE:
      return {
        ...state,
        order: {
          loading: false,
          success: null,
          error: action.payload,
        },
      };

    case SET_SCANNED_TABLE_ID:
      return {
        ...state,
        scannedTableId: action.payload,
      };

    case FETCH_FOODS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_FOODS_SUCCESS:
      return {
        ...state,
        foods: action.payload,
        loading: false,
      };

    case FETCH_FOODS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default rootReducer;
