// Footer.jsx
import React, { useState } from 'react';
import { FaHome, FaInfo, FaComment, FaShoppingCart } from 'react-icons/fa';
import './footer.scss';
import { useDispatch, useSelector } from 'react-redux';
import CartDetails from '../pages/CartDetails';

const Footer = () => {
  const dispatch = useDispatch();
  const cartCount = useSelector((state) => state.cart.length);

  const [isCartOpen, setIsCartOpen] = useState(false);

  const handleCartClick = () => {
    setIsCartOpen(!isCartOpen);
  };

  return (
    <footer className="footer">
      <div className="icon">
        <FaHome />
      </div>
      <div className="icon">
        <FaInfo />
      </div>
      <div className="icon">
        <FaComment />
      </div>
      <div className="icon" onClick={handleCartClick}>
        <FaShoppingCart />
        {cartCount > 0 && <span className="cart-counter">{cartCount}</span>}
      </div>

      {isCartOpen && <CartDetails onClose={() => setIsCartOpen(false)} />}
    </footer>
  );
};

export default Footer;
