// CartDetails.js

import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { deleteFromCart, placeOrder, fetchFoods } from '../actions/actions';
import './CartDetails.scss';
import axios from 'axios'; // Import axios for making HTTP requests

const CartDetails = ({ cartItems, onClose, dispatchDeleteItem, dispatchPlaceOrder, dispatchFetchFoods, order }) => {
  const [quantities, setQuantities] = useState(cartItems.map(() => 1));
  const [loading, setLoading] = useState(false);
  const componentRef = useRef();

  const calculateItemTotal = (item, index) => {
    let itemTotal = item.price * quantities[index]; // Calculate base price * quantity

    // Add the price of each request to the item total
    item.requests.forEach((request) => {
      itemTotal += request.addOnPrice;
    });

    return itemTotal.toFixed(2);
  };

  const calculateTotal = () => {
    let total = 0;
    cartItems.forEach((item, index) => {
      total += parseFloat(calculateItemTotal(item, index));
    });
    return total.toFixed(2);
  };

  useEffect(() => {
    // Display an alert when the order is successfully placed or fails
    if (order && order.success) {
      alert('Order placed successfully!');
    } else if (order && order.error) {
      alert(`Order placement failed: ${order.error}`);
    }
  }, [order]);

  const handleDecrement = (index) => {
    const updatedQuantities = [...quantities];
    updatedQuantities[index] = Math.max(1, updatedQuantities[index] - 1);
    setQuantities(updatedQuantities);
  };

  const handleIncrement = (index) => {
    const updatedQuantities = [...quantities];
    updatedQuantities[index] += 1;
    setQuantities(updatedQuantities);
  };

  const generatePrintContent = () => {
    let content = ''; // Initialize content string

    // Loop through cart items and add details to content
    cartItems.forEach((item, index) => {
      content += `${item.title} x ${quantities[index]} \n`;
      item.requests.forEach(request => {
        content += `${request.name}\n`;
      });
    });

    // Add total to content
    // content += `Total: RM ${calculateTotal()}`;

    return content;
  };

  const handlePlaceOrder = async () => {
    setLoading(true);

    // Calculate total amount, SST, and service charge
    const totalAmount = calculateTotal();
    const sst = totalAmount * 0.08; // 8% SST
    const serviceCharge = totalAmount * 0.10; // 10% service charge

    // Create an array to store items grouped by category
    const itemsByCategory = {};

    cartItems.forEach((item, index) => {
      // Check if the category exists in the itemsByCategory object
      if (!itemsByCategory[item.category]) {
        itemsByCategory[item.category] = [];
      }

      // Add the item to the respective category
      itemsByCategory[item.category].push({
        _id: item._id,
        quantity: quantities[index],
        requests: item.requests, // Include requests data here
      });
    });

    try {
      // Dispatch place order for each category
      for (const category in itemsByCategory) {
        await dispatchPlaceOrder(totalAmount, itemsByCategory[category], category, sst, serviceCharge);
      }

      // Display an alert when the order is successfully placed
      alert('Order placed successfully!');
      
      // Clear the cart after successfully placing the order
      cartItems.forEach((item, index) => {
        dispatchDeleteItem(index);
      });

      // Fetch updated foods to reflect changes in cart
      await dispatchFetchFoods();
  
      onClose();
    } catch (error) {
      console.error('Error placing order:', error);
      // Handle error appropriately, e.g., display error message
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteItem = (index) => {
    dispatchDeleteItem(index);
  };

  return (
    <div className="cart-details-container">
      <h2>Cart Details</h2>
      <ul ref={componentRef}>
        {cartItems.length > 0 ? (
          cartItems.map((item, index) => (
            <li key={index} className="cart-item">
              <img src={item.img} alt={item.title} className="cart-item-img" />
              <div className="cart-item-details">
                <div className="cart-item-title">{item.title}</div>
                <div className="cart-item-price">
                  {item.requests.map((request) => (
                    <div key={request.id}>{request.name} RM {request.addOnPrice}</div>
                  ))}
                  RM {item.price.toFixed(2)}
                </div>
                <div className="cart-item-quantity">
                  <button onClick={() => handleDecrement(index)}>-</button>
                  {quantities[index]}
                  <button onClick={() => handleIncrement(index)}>+</button>
                </div>
              </div>
              <div
                className="cart-item-delete"
                onClick={() => handleDeleteItem(index)}
              >
                &#10006;
              </div>
            </li>
          ))
        ) : (
          <li>Your cart is empty</li>
        )}
      </ul>
      <div className="cart-total">Total: RM {calculateTotal()}</div>
      <button onClick={handlePlaceOrder}>Place Order</button>
      <button onClick={onClose}>Close</button>
      
      {loading && <div>Loading...</div>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  cartItems: state.cart,
  order: state.order,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchDeleteItem: (index) => dispatch(deleteFromCart(index)),
  dispatchPlaceOrder: (amount, foods, category) => dispatch(placeOrder(amount, foods, category)),
  dispatchFetchFoods: () => dispatch(fetchFoods()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartDetails);
